import React from 'react';
import { Store } from 'redux';
import { useSelector } from 'react-redux';
import { css, StyleSheet } from 'aphrodite/no-important';
import {
  Ad,
  AppContent,
  baseUnit,
  ErrorBoundary,
  MEDIA_BREAKPOINTS,
  OneHCThreeVC,
  SecondarySectionTitle,
  SectionHeader,
  SectionWithTitle,
  MEDIUM_SCREEN_SIZE,
  SMALL_SCREEN_SIZE,
  ExpandableHC,
  ThreeHCDynamicList,
  SportradarFactory,
  SPORTRADAR_NAMES,
  LinkWithButtonStyle,
  configureLeague,
  tickerCallback,
} from 'mm-ui-components';
import { Theme } from '../../components/connectedComponents/Theme';
import { HeadScripts } from '../../components/connectedComponents/HeadScripts';
import { Page } from '../Page';
import { ErrorReporter } from '../../errorReporting/errorReporter.type';
import { AppActions, AppState } from '../../store/initialStateRegistration';
import { useMeasureHydrateTime } from '../useMeasureHydrateTime';
import { NavigationLayout } from '../../components/connectedComponents/NavigationLayout';
import { MetaTags } from '../../components/connectedComponents/MetaTags';
import {
  getMetadataDescription,
  getMetadataImage,
  getMetadataTitle,
  getSectionArticlesByKey,
  getSectionTitleByKey,
  getHreflangData,
  getShowMorePaginationTextByKey,
  getShowMorePaginationURLByKey,
  getGridSectionLogo,
  getMoreButtonByKey,
} from '../../store/template/homePage/homePageBuenosAires/homePageBuenosAires.selectors';
import { SECTION_NAMES, SLOT_NAMES } from '../templates.const';
import {
  getAdSlotId,
  getAdSlotOnSuccess,
  getCanonicalUrl,
  getPaletteColors,
  getUseFormatMinutesHoursAgo,
} from '../../store/config/config.selectors';
import { LoadMoreButton, useFetchNextPosts } from '../../components/connectedComponents/LoadMoreButton';
import { HomePageBuenosAiresExpandableSectionsWithMore } from '../../store/template/homePage/homePageBuenosAires/homePageBuenosAires.initialState';
import { AdditionalStyleSheet } from '../templates.utils';
import { createSIMetaImage } from '../../store/template/template.utils';

interface HomePageBuenosAiresProps {
  store: Store<AppState, AppActions>;
  errorProvider: ErrorReporter;
}

const getArticlesInTemplate = (state: AppState) => {
  const topSectionArticles = getSectionArticlesByKey(SECTION_NAMES.TOP_SECTION)(state) || [];
  const section2Articles = getSectionArticlesByKey(SECTION_NAMES.SECTION2)(state) || [];
  const section3Articles = getSectionArticlesByKey(SECTION_NAMES.SECTION3)(state) || [];
  const section4Articles = getSectionArticlesByKey(SECTION_NAMES.SECTION4)(state) || [];

  return [
    ...topSectionArticles,
    ...section2Articles,
    ...section3Articles,
    ...section4Articles,
  ];
};

export const HomePageBuenosAiresComponent: React.FunctionComponent<HomePageBuenosAiresProps> = ({ store, errorProvider }) => {
  useMeasureHydrateTime();
  return (
    <Page store={store} errorProvider={errorProvider}>
      <ErrorBoundary>
        <HomePageBuenosAiresContent />
      </ErrorBoundary>
    </Page>
  );
};

const createStyle = () => StyleSheet.create({
  sectionsWrapper: {
    gridRowGap: `${baseUnit * 2}px`,
    [MEDIA_BREAKPOINTS.medium]: {
      maxWidth: `${MEDIUM_SCREEN_SIZE}px`,
      margin: '0 auto',
    },
    [MEDIA_BREAKPOINTS.small]: {
      maxWidth: `${SMALL_SCREEN_SIZE}px`,
      margin: '0 auto',
    },
    display: 'grid',
    gridTemplateColumns: '1fr',
    marginBottom: `${baseUnit * 0.5}px`,
    justifyItems: 'center',
  },
  adContainer: {
    height: '140px',
    [MEDIA_BREAKPOINTS.large]: {
      marginBottom: `${baseUnit * 1.5}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      marginBottom: `${baseUnit * 1.5}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      marginBottom: `${baseUnit}px`,
    },
  },
  moreContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: `${baseUnit}px`,
  },
  widget: {
    margin: '0',
  },
  tickerWidget: {
    marginBottom: '40px',
    padding: '0',
  },
  titleContainer: {
    marginBottom: '20px',
  },
});

const twoSectionStyles = StyleSheet.create({
  style: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    [MEDIA_BREAKPOINTS.medium]: {
      gridTemplateColumns: '1fr',
    },
  },
}) as AdditionalStyleSheet;

const moreButtonStyle = StyleSheet.create({
  style: {
    borderRadius: '4px',
    padding: '15px 30px',
  },
}) as AdditionalStyleSheet;

const additionalFeedStyle = (darkGray: string) => StyleSheet.create({
  style: {
    textDecoration: 'none',
    borderRadius: 'unset',
    backgroundColor: 'inherit',
    padding: '0',
    // @ts-ignore
    fontWeight: 'inherit !important',
    fontSize: 'inherit !important',
    lineHeight: 'inherit !important',
    color: 'inherit',
    boxShadow: 'none',
    ':hover': {
      backgroundColor: 'inherit',
      boxShadow: 'none',
      color: darkGray,
    },
  },
}) as AdditionalStyleSheet;

export const HomePageBuenosAiresContent = () => {
  const topSectionArticles = useSelector(getSectionArticlesByKey(SECTION_NAMES.TOP_SECTION)) || [];
  const topSectionTitle = useSelector(getSectionTitleByKey(SECTION_NAMES.TOP_SECTION));
  // const section1Title = useSelector(getSectionTitleByKey(SECTION_NAMES.SECTION1));
  const contentSection2Title = useSelector(getSectionTitleByKey(SECTION_NAMES.SECTION2));
  const contentSection2Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.SECTION2));
  const contentSection2Link = useSelector(getMoreButtonByKey(SECTION_NAMES.SECTION2));
  const contentSection3Title = useSelector(getSectionTitleByKey(SECTION_NAMES.SECTION3));
  const contentSection3Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.SECTION3));
  const contentSection3Link = useSelector(getMoreButtonByKey(SECTION_NAMES.SECTION3));
  const contentSection4Title = useSelector(getSectionTitleByKey(SECTION_NAMES.SECTION4)) || '';
  const contentSection4Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.SECTION4));
  const contentSection4Logo = useSelector(getGridSectionLogo(SECTION_NAMES.SECTION4));
  const isSVGTitle = contentSection4Logo?.src ? '' : contentSection4Title;
  const colors = useSelector(getPaletteColors);
  const feedButtonStyle = additionalFeedStyle(colors.darkGrey);

  const topAdId = useSelector(getAdSlotId(SLOT_NAMES.TOP_AD));
  const topAdOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.TOP_AD));
  const belowTopSectionAdId = useSelector(getAdSlotId(SLOT_NAMES.BELOW_TOP_SECTION));
  const belowTopSectionAdOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.BELOW_TOP_SECTION));
  const belowSecondSectionAdId = useSelector(getAdSlotId(SLOT_NAMES.BELOW_SECOND_SECTION));
  const belowSecondSectionAdOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.BELOW_SECOND_SECTION));
  const oneOnOneAdId = useSelector(getAdSlotId(SLOT_NAMES.ONE_ON_ONE));
  const oneOnOneAdOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.ONE_ON_ONE));
  const metaTitle = useSelector(getMetadataTitle);
  const metaDescription = useSelector(getMetadataDescription);
  const canonical = useSelector(getCanonicalUrl);
  let pageImage = useSelector(getMetadataImage);
  pageImage = createSIMetaImage(canonical, pageImage);
  const articles = useSelector(getArticlesInTemplate);
  const hreflangData = useSelector(getHreflangData) || [];
  const league = configureLeague(canonical);

  const useFormatMinutesHoursAgo = useSelector(getUseFormatMinutesHoursAgo);
  const style = createStyle();
  const paddingFactors = { large: 0.5, medium: 0.5, small: 0.5 };

  return (
    <>
      <MetaTags
        articles={articles}
        title={metaTitle}
        description={metaDescription}
        pageImage={pageImage}
        alternateUrls={hreflangData}
      />
      <HeadScripts />
      <Theme>
        <AppContent>
          <NavigationLayout mainWrapperConfigThemeKey="mainContent" noMarginTop isFullBleed>
            {league && (
            <SportradarFactory
              name={SPORTRADAR_NAMES.TICKER}
              wrapperStyle={style.tickerWidget}
              widgetStyle={style.widget}
              sportradarProps={{ sport: league, onItemClick: tickerCallback }}
            />
            )}
            <Ad id={topAdId} onSuccess={topAdOnSuccess} setMinHeight />
            <div className={css(style.sectionsWrapper)}>
              <SectionWithTitle>
                <SectionHeader
                  moreButtonSvg={null}
                  moreButtonText={null}
                  paddingFactors={paddingFactors}
                >
                  <SecondarySectionTitle>{topSectionTitle}</SecondarySectionTitle>
                </SectionHeader>
                <OneHCThreeVC cards={topSectionArticles} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} paddingFactors={paddingFactors} />
              </SectionWithTitle>
              {/* <SectionWithTitle>
                <SectionHeader
                  moreButtonSvg={null}
                  moreButtonText={null}
                  paddingFactors={paddingFactors}
                >
                  <SecondarySectionTitle>{section1Title}</SecondarySectionTitle>
                </SectionHeader>
                <h1>STN PLaceholder</h1>
              </SectionWithTitle> */}
              <Ad id={belowTopSectionAdId} onSuccess={belowTopSectionAdOnSuccess} setMinHeight />
              <SectionWithTitle additionalStyle={twoSectionStyles}>
                <SectionWithTitle>
                  <SectionHeader
                    moreButtonSvg={null}
                    moreButtonText={null}
                    paddingFactors={paddingFactors}
                  >
                    <SecondarySectionTitle>
                      {contentSection2Link?.href ? (
                        <LinkWithButtonStyle additionalStyle={feedButtonStyle} href={contentSection2Link.href}>
                          {contentSection2Title}
                        </LinkWithButtonStyle>
                        ) : (
                          contentSection2Title
                      )}
                    </SecondarySectionTitle>
                  </SectionHeader>
                  <ExpandableHC
                    cards={contentSection2Articles}
                    showImageHeightByAspectRatio
                    paddingFactors={paddingFactors}
                    useFormatMinutesHoursAgo={useFormatMinutesHoursAgo}
                  />
                  <LoadMoreFeedPostsButtonConnected section={SECTION_NAMES.SECTION2} />
                  <Ad id={belowSecondSectionAdId} onSuccess={belowSecondSectionAdOnSuccess} setMinHeight showInDesktop={false} />
                </SectionWithTitle>
                <SectionWithTitle>
                  <SectionHeader
                    moreButtonSvg={null}
                    moreButtonText={null}
                  >
                    <SecondarySectionTitle>
                      {contentSection3Link?.href ? (
                        <LinkWithButtonStyle additionalStyle={feedButtonStyle} href={contentSection3Link.href}>
                          {contentSection3Title}
                        </LinkWithButtonStyle>
                        ) : (
                          contentSection3Title
                      )}
                    </SecondarySectionTitle>
                  </SectionHeader>
                  <ExpandableHC
                    cards={contentSection3Articles}
                    showImageHeightByAspectRatio
                    paddingFactors={paddingFactors}
                    useFormatMinutesHoursAgo={useFormatMinutesHoursAgo}
                  />
                  <LoadMoreFeedPostsButtonConnected section={SECTION_NAMES.SECTION3} />
                </SectionWithTitle>
              </SectionWithTitle>
              <Ad id={oneOnOneAdId} onSuccess={oneOnOneAdOnSuccess} setMinHeight />
              {contentSection4Articles?.length && (
                <SectionWithTitle>
                  <ThreeHCDynamicList image={contentSection4Logo} title={isSVGTitle} cards={contentSection4Articles} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
                </SectionWithTitle>
              )}
            </div>
          </NavigationLayout>
        </AppContent>
      </Theme>
    </>
  );
};

export const LoadMoreFeedPostsButtonConnected = ({ section }: {section: HomePageBuenosAiresExpandableSectionsWithMore}) => {
  const shouldDisplay = !!useSelector(getShowMorePaginationURLByKey(section));
  const text = useSelector(getShowMorePaginationTextByKey(section));
  const onClick = useFetchNextPosts(section, getShowMorePaginationURLByKey);
  return <LoadMoreButton {...{ onClick, shouldDisplay, text }} additionalStyle={moreButtonStyle} />;
};
